<template>
  <div id="container" class="">
    <section id="">
      <div class="wrap">
        <FullCalendar :options="calendarOptions"/>
        <div class="calender_nav">
          <ul class="cal_guide flex">
            <li class="cal_01 flex">
              <span></span>
              <p>：講義など</p>
            </li>
            <li class="cal_02 flex">
              <span></span>
              <p>：月例会・研修会</p>
            </li>
            <li class="cal_03 flex">
              <span></span>
              <p>：メディア出演</p>
            </li>
            <li class="cal_04 flex">
              <span></span>
              <p>：入塾受付</p>
            </li>
          </ul>
          <div class="cal_guide_link alnC sp_alnL">
            カレンダーをご自身のGoogleカレンダーと同期したい方は<a href="/calendar_guide">こちら</a>を参照してください
          </div>
        </div>
      </div>
    </section>
    <div id="b_listBox">
      <ul id="b_list">
        <li>
          <router-link to="/">トップページ</router-link>
        </li>
        <li>カレンダー</li>
      </ul>
    </div>
  </div><!--//＃container-->
</template>

<script>
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import googleCalendarPlugin from '@fullcalendar/google-calendar';

export default {
  components: {
    FullCalendar
  },
  data() {
    let _calendarOptions = window.$FukuganCalendar;
    _calendarOptions.plugins = [
      dayGridPlugin,
      googleCalendarPlugin
    ]
    return {
      calendarOptions: _calendarOptions
    }
  }
}
</script>
